<template>
  <div class="pt-4">
    <v-row v-if="selectedProperty && !propertyPromotionSettings?.active" class="my-0 py-0 pl-4 mt-3 pr-3 pb-4" style="flex:none;">
      <hb-notification
        v-model="notEnabledNotification"
        title="Note:"
        type="caution"
        not-dismissable
      >
        <span v-html="promoMgmtMessage"></span>
      </hb-notification>
    </v-row>
    <template v-if="showDataViewer">
      <div>
        <v-row v-if="spaceGroupsNotification" class="my-0 py-0 pl-4 mt-3 pr-3" style="flex:none;">
          <hb-notification
            v-model="spaceGroupsNotification"
            title="Note:"
            type="caution"
            not-dismissable
          >
            <span v-html="spaceGroupsNotificationMessage"></span>
          </hb-notification>
        </v-row>
        <v-row v-if="notification" class="my-0 py-0 pl-4 mt-3 pr-3" style="flex:none;">
          <hb-notification
            v-model="notification"
            title="Note:"
            type="caution"
            not-dismissable
          >
            <span v-html="notificationMessage"></span>
          </hb-notification>
        </v-row>

        <v-row class="my-0 py-0 pl-4 mt-3 pr-3" style="flex:none;">
          <hb-notification
            v-model="notification"
            title=""
            :type="propertyPromotionSettings?.sync_state == 'sync_failed' ? 'error' : 'info'"
            not-dismissable
          >
            <template> 
              <span v-if="propertyPromotionSettings?.sync_state == 'never_synced'">
                Never Synced
              </span>
              <span v-else-if="propertyPromotionSettings?.sync_state == 'sync_in_progress'">
                Syncing is progress, {{ propertyPromotionSettings?.last_job_details?.progress?.prct || 0.00 }}% completed...
              </span>
              <span v-else-if="propertyPromotionSettings?.sync_state == 'sync_failed'">
                Last Sync Failed
              </span>
              <span v-else>
                Last Synced on {{propertyPromotionSettings?.last_synced_at | formatLocalDateTimeServices}}{{ propertyPromotionSettings?.last_synced_by_name ? ` by ${propertyPromotionSettings?.last_synced_by_name}` : '' }}
              </span>
            </template>
            <template v-slot:actions>
              <hb-btn
                 v-if="propertyPromotionSettings?.sync_state == 'sync_in_progress'"
                color="secondary"
                small
                @click="reloadPromoManagement()"
                :loading="loading"
              >
                Reload
              </hb-btn>
              <hb-btn
                color="secondary"
                small
                @click="syncPromoManagement()"
                :loading="loading"
              >
                Manual Sync
              </hb-btn>
            </template>
          </hb-notification>
        </v-row>
      </div>
      <div
        :style="{ height: reportHeight + 'px' }"
        v-resize="setHeight"
      >
        <hb-report
          ref="report"
          :key="report_key"
          report_type="property_promotion_management"
          :show_divider="false"
          :grid-conf="gridConf"
          :actions_panel="bulkActions"
          show_search
          :column-conf="columnConf"
          left_slot
          @gridReady="(api) => (gridApi = api)"
        >
          <template v-slot:left>
            <span v-if="!defaultGroupingProfile || !defaultGroupingProfile.name">
              <v-skeleton-loader
                max-width="70%"
                type="text"
                class="profile-loader"
              />
              <!--default will be 5-->
            </span>
            <div v-else class="hb-font-header-3-medium pt-2">
              Grouping Profile:
              <span :class="{ none: !defaultGroupingProfile.name }">{{
                defaultGroupingProfile.name || "None"
              }}</span>
            </div>
          </template>
        </hb-report>

        <!-- Disable promotion management profile -->
        <ConfirmationPopup v-once ref="confirmationPopup" />
        <!-- Disable promotion management profile -->
        <AssignPromoPlan 
          @promoApplied="triggerTableRefresh" 
          @promoClosed="updateRowData(selectedGroupId)"
          ref="assignPromo" />
      </div>
    </template>
    <hb-empty-state v-if="getEmptyTextMsg.length && !showDataViewer" :message="getEmptyTextMsg" />
  </div>
</template>
<script>
import { notificationMixin } from "@/mixins/notificationMixin.js";

import HbReport from "@/components/assets/HummingbirdReportViewer.vue";
import MultiLineRenderer from "@/components/BI/MultiLineRenderer.vue";
import StatusCellRenderer from "@/components/BI/StatusCellRenderer.vue";
import PromoPlanCellRenderer from "@/components/BI/PromoPlanCellRenderer.vue";
import PromotionTooltip from "@/components/revenue_management/promotion_management/PromotionTooltip.vue";
import BooleanCellRenderer from "@/components/BI/BooleanCellRenderer";
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";
import PromotionManagementByPropertyActions from "@/components/revenue_management/promotion_management/PromotionManagementByPropertyActions.vue";
import api from "../../../assets/api.js";

import { EventBus } from "../../../EventBus.js";
import { mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import AssignPromoPlan from "./AssignPromoPlan.vue";

export default {
  name: "PromotionManagementByProperty",

  components: {
    HbReport,
    MultiLineRenderer,
    StatusCellRenderer,
    BooleanCellRenderer,
    PromoPlanCellRenderer,
    PromotionTooltip,
    ConfirmationPopup,
    AssignPromoPlan
},

  props: {
    permissions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      report_key: 1,
      reportHeight: 0,
      gridApi: null,
      notification: true,
      notEnabledNotification: false,
      selectedGroupId: "",
      loading: false,
      notificationMessage:
        "Changes made to Promotion or Promotion Plan assignment will only take effect after midnight if not manually synced. Select Manual Sync to sync the changes immediately.",
      spaceGroupsNotificationMessage: "Space groups are not set up for this property. To enable it, please follow these steps: </br> <ul><li>Go to <strong>Hummingbird Settings > Space Groups </strong> and ensure space groups are set up for your property for revenue management.</li><li>Once the space groups are created, navigate to <strong>Hummingbird Settings > Revenue Management</strong>.</li><li>Select your property, scroll to the <strong> Promotions Management </strong> section and enable it.</li><li>Select the desired <strong> Space Group </strong> from the dropdown and click <strong> Save</strong>.</li></ul>",
      promoMgmtMessage: "Promotion Management is not enabled for this property. To enable it, please follow these steps: </br> <ul><li>Go to <strong> Hummingbird Settings > Space Groups </strong> and ensure space groups are set up for your property for revenue management.</li><li>Once the space groups are created/confirmed, navigate to <strong> Hummingbird Settings > Revenue Management </strong>.</li><li>Select your property, scroll to the <strong> Promotions Management </strong> section, and click <strong> Turn On </strong> to enable it for your property.</li><li>Select the desired <strong> Space Group </strong> from the dropdown and click <strong> Save</strong>.</li></ul>"
    };
  },

  watch: {
    selectedProperty: {
      handler(prev, next) {
        this.fetchProfileAndConfigurations();
        //code to reset applied filters in this report when property changes
        if (this.$refs?.report) this.$refs?.report?.resetReport();
      },
      immediate: true,
    },
  },

  mixins: [notificationMixin],
  mounted() {
    let events = {
      toggleProfileStatus: this.toggleProfileStatus,
      refreshTable: this.triggerTableRefresh,
      assignPromoOnline: this.showAssignPromo,
      assignPromoInstore: this.showAssignPromo,

    };
    EventBus.$on("promotionManagementEvents", ({ event, data }) =>
      events[event](data, event)
    );
  },
  beforeDestroy() {
    EventBus.$off("promotionManagementEvents");
  },

  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      selectedProperty: "revManStore/getSelectedProperty",
      defaultGroupingProfile: "revManStore/promotionGroupingProfile",
      propertyPromotionSettings: "revManStore/getPropertyPromotionSettings",
      noSpaceGroups: "reportStore/getNoTableData",
    }),
    bulkActions() {
      return this.permissions.assign_promotions ? ['bulk_edit'] : []
    },
    spaceGroupsNotification(){
      return this.noSpaceGroups;
    },
    showLocalPropertySelector() {
      return this.properties?.length > 1;
    },
    getEmptyTextMsg() {
      let text = "";
      if (!this.selectedProperty) {
        text =
          "Please select a property from the dropdown above.";
      } else if (!this.propertyPromotionSettings?.active) {
        this.notEnabledNotification = true;
      }
      return text;
    },
    showDataViewer() {
      return this.selectedProperty && this.propertyPromotionSettings?.active;
    },
    gridConf() {
      return {
        props: {
          BIHelpers: {
            kebab: {
              enabled: true,
              icon: "mdi-dots-vertical",
              component: {
                definition: PromotionManagementByPropertyActions,
                props: {
                  propertyId: this.selectedProperty,
                  permissions: this.permissions,
                },
              },
              action: "click",
            },
          },
          getRowId: (params) => params.data.spacegroup_id_hash,
          defaultColDef: {
            floatingFilter: false,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          },
          overlayNoRowsTemplate:
            '<span style="font-size: 20px;padding: 10px;">No Space Groups</span>',
        },
      };
    },
    columnConf() {
      return {
        spacegroup_spacetype: {
          pinned: "left",
        },
        spacegroup_amenities: {
          pinned: "left",
        },
        spacegroup_size: {
          pinned: "left",
        },
        spacegroup_instore_online_promo: {
          pinned: "right",
          cellRenderer: "PromoPlanCellRenderer",
          cellRendererParams: {
            viewPermission: this.permissions.view_promotion_plans
          },
          tooltipComponent: "PromotionTooltip",
          tooltipComponentParams: {
            showToolTip: (val) => !!Object.keys(val).length,
            showPromoDetails: true,
            rendererParams: (val) => {
              return {
                promotion_type: val.spacegroup_instore_online_promo.type,
                name: val.spacegroup_instore_online_promo.name,
                description: val.spacegroup_instore_online_promo.description,
              }
            }
          },
        },
        spacegroup_online_promo: {
          pinned: "right",
          cellRenderer: "PromoPlanCellRenderer",
          cellRendererParams: {
            viewPermission: this.permissions.view_promotion_plans
          },
          tooltipComponent: "PromotionTooltip",
          tooltipComponentParams: {
            showToolTip: (val) => !!Object.keys(val).length,
            showPromoDetails: true,
            rendererParams: (val) => {
              return {
                promotion_type: val.spacegroup_online_promo.type,
                name: val.spacegroup_online_promo.name,
                description: val.spacegroup_online_promo.description
              }
            }
          },
        },
        spacegroup_promotion_management_active: {
          cellRenderer: "BooleanCellRenderer",
          pinned: "right",
          lockPosition: "right",
          suppressNavigable: true,
          cellRendererParams: {
            icon: true,
          },
          cellClass: "boolean-icons",
        },
      };
    },
  },

  methods: {
    ...mapActions({
      fetchCurrentPropertyPromotionConfiguration:
        "revManStore/fetchCurrentPropertyPromotionConfiguration",
    }),
    setHeight() {
      let header = 20;
      let stepper = 100;
      let propertySelector = this.showLocalPropertySelector ? -35 : 53;
      let footer = 95;
      let heading = 65;
      let padding = 40;
      this.reportHeight =
        window.innerHeight -
        header -
        stepper -
        heading -
        footer -
        padding +
        propertySelector;
    },

    setDynamicRuntimeProperties(data = null) {
      this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: data ? [data] : undefined,
      });
    },

    showAssignPromo(data, event) {
      this.$refs.assignPromo.show(data, event);
      this.selectedGroupId = data.spacegroup_id_hash;
    },

    triggerTableRefresh(data = {}, columns = []) {
      if (!this.$route.query.dynamicRun) {
        this.$router.push({ path: "", query: { dynamicRun: true } });
      }
      this.setDynamicRuntimeProperties(this.selectedProperty);
      EventBus.$emit("unit_edited");
    },
    /**
     * Fire configuration fetch and update table
     */
    async fetchProfileAndConfigurations() {
      if (!this.selectedProperty) return;
      this.loading = true;
      // await this.fetchCurrentPropertyPromotionConfiguration()
      try {
        this.triggerTableRefresh();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    /**
    * Disable promotion management for that spacegroup
    */
    async toggleProfileStatus(rowData) {
      // Status, Tokens and Labels
      const {
        spacegroup_id_hash,
        spacegroup_promotion_management_active,
      } = rowData;
      let toggle = !Boolean(spacegroup_promotion_management_active);
      let statusLabel = { true: "Enable", false: "Disable" }[toggle];

      if (!this.selectedProperty) return;

      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: `Confirm ${statusLabel}`,
          message: `Are you sure you want to <strong> ${statusLabel.toLowerCase()} the promotion management profile </strong> you assigned to this space category?`,
          resolver: `${statusLabel}`,
        })
        .catch(() => false);

      if (confirmed) {
        let reqBody = {
          active: toggle,
          property_promotion_management_settings_id: this.propertyPromotionSettings.id,
          unit_group_hashed_ids: [spacegroup_id_hash]
        }
      return api
        .put(
          this,
          api.updatePromoManagementSpacegroupStatusUrl(this.selectedProperty),
          reqBody,
          "",
          false
        )
        .then(() => {
          this.showMessage();
          this.updateRowData(spacegroup_id_hash, {
            spacegroup_promotion_management_active: reqBody.active,
          });
          this.triggerTableRefresh();
        })
        .catch((err) => {
          this.showMessage("error");
        });
      }
      this.updateRowData(spacegroup_id_hash, {});
    },

    updateRowData(pKey, data = {}) {
      let node = null;
      if (this.gridApi) node = this.gridApi.getRowNode(pKey);
      if (node) node.setData({ ...node.data, ...data });
    },

    showMessage(type = "success", message = "") {
      let description = !message
        ? `${
            type == "success"
              ? "You have successfully updated"
              : "Unable to update"
          } Space Group settings.`
        : message;
      this.showMessageNotification({ type, description });
    },
    /**
    * Sync Promo management for property.
    */
    async syncPromoManagement() {
      this.loading = true;
      try {
        let data = await api.get(
          this,
          api.syncPropertyPromoManagementUrl(this.selectedProperty)
        );
      } catch (err) {
        console.error("Property management sync error", err);
      }
      await this.fetchCurrentPropertyPromotionConfiguration()
      this.loading = false;
    },
    async reloadPromoManagement() {
      this.loading = true;
      try {
        await this.fetchCurrentPropertyPromotionConfiguration()
      } catch (err) {
        console.error("Property management sync error", err);
      }
      this.loading = false;
    },
  },
};
</script>
