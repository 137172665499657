<template>
  <div class="section-content pt-4">
    <template v-if="hasSpaceData">
      <promo-management-spaces
        class="pr-4"
        type="promotion"
        @setPreviousData="setPreviousData($event)"
      />
    </template>
    <template v-else>
      <div ref="rmTopSection">
        <hb-header full :divider="false">
          <hb-page-header
            title="Promotion Management"
            description="Create, configure and manage promotions and discounts."
          >
          </hb-page-header>
        </hb-header>
        <v-divider />
      </div>
      <div ref="rmPropertySection" class="d-flex align-center">
        <HbPropertySelector
          v-show="properties.length !== 1"
          v-model="property"
          :items="properties"
          item-value="id"
          item-text="name"
        />
      </div>
      <!-- MAIN SECTION STARTS -->
      <div>
        <!-- TABS -->
        <div ref="rmTabSection">
          <hb-header one-action :padding="false" align-top>
            <template v-slot:left>
              <hb-tabs v-model="activeTab" class="mt-n2">
                <v-tab v-for="item in tabOptions" :key="item.key">
                  {{ item.label }}
                  <hb-tooltip bottom>
                    <template v-slot:item>
                      <hb-icon class="ml-2" small>mdi-information</hb-icon>
                    </template>
                    <template v-slot:header>
                      <span v-html="item.description" />
                    </template>
                  </hb-tooltip>
                </v-tab>
              </hb-tabs>
            </template>
            <template v-slot:right>
              <hb-btn
                v-if="isLibraries"
                class="mr-1"
                :class="{ 'mr-3': $vuetify.breakpoint.xs }"
                icon
                tooltip="Sidebar"
                @click="showTertiary = !showTertiary"
                >mdi-table-actions-custom-1</hb-btn
              >
            </template>
          </hb-header>
          <!-- <v-divider></v-divider> -->
        </div>
        <!-- TAB ITEMS -->

        <v-tabs-items class="rm-tab-items" v-model="activeTab">
          <v-tab-item class="tab-item">
            <Promotion-management-by-property class="pr-4" :permissions="permissions"/>
          </v-tab-item>
          <v-tab-item class="tab-item">
            <Discount-management-by-property class="pr-4" :permissions="permissions"/>
          </v-tab-item>
          <v-tab-item class="tab-item">
            <promotion-library
              :show-tertiary="showTertiary"
              :permissions="permissions"
              :class="{ 'pr-4': !showTertiary }"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
  </div>
</template>
<script>
import PromotionLibrary from "./PromotionLibrary.vue";
import PromotionManagementByProperty from "./PromotionManagementByProperty.vue";
import DiscountManagementByProperty from "./DiscountManagementByProperty.vue";
import PromoManagementSpaces from "../utils/Spaces.vue";
import { EventBus } from "../../../EventBus";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "PromotionManagement",
  data() {
    return {
      promoManagementPermissions: [
        "manage_promotion_management",
        "view_promotion_plans",
        "manage_promotions",
        "manage_promotion_plans",
        "assign_discounts",
        "assign_promotions",
      ],
      tabOptions: [
        {
          label: "Promotion Management by Property",
          key: "promotion-management",
          description:
            "Assign and manage promotions for each space group at your property. To create space groups, go to Space Groups under Hummingbird Settings. To Assign a space group for a property, go to Revenue Management under Hummingbird Settings > Promotion Management > Space Group.",
        },
        {
          label: "Discount Management by Property",
          key: "discount-management",
          description:
            "Assign and manage discounts for your properties.",
        },
        {
          label: "Libraries",
          key: "libraries",
          description: "View, create and edit all promotions and discounts in the Libraries. Libraries also provide ability to see Promotion Plans that are created.",
        },
        //{ label: "Promotion Plans", key: 3, description: "Choose the pricing system that will be used to manage your property's rates.<br> Add and manage verbiage and feature sorting for the Mariposa website value pricing tiers." },
        // {
        //   label: "Promotion Plan Library",
        //   key: 3,
        //   description:
        //     "Create and manage promotion plans on a corporate level.",
        // },
      ],
      activeTab: 0,
      property: null,
      reportHeight: 0,
      viewPromo: false,
      promoData: {},
      showTertiary: true,
    };
  },
  props: {},
  components: {
    PromotionLibrary,
    PromotionManagementByProperty,
    DiscountManagementByProperty,
    PromoManagementSpaces,
  },
  async mounted() {
    if (!this.showLocalPropertySelector) {
      this.property = this.selectedProperty;
    }
    await this.fetchPromotions();
    if(this.permissions?.view_promotion_plans) await this.fetchPromotionPlans();
  },
  computed: {
    ...mapGetters({
      spaceData: "revManStore/getSpaceData",
      properties: "propertiesStore/filtered",
      selectedProperty: "revManStore/getSelectedProperty",
      hasRolePermission: "authenticationStore/rolePermission",
    }),
    permissions() {
      let promoPermission = {};
      this.promoManagementPermissions.forEach((permission) => {
        promoPermission[permission] = this.hasRolePermission(permission);
      });
      return promoPermission;
    },
    hasSpaceData() {
      return Object.keys(this.spaceData).length;
    },
    showLocalPropertySelector() {
      return this.properties?.length > 1;
    },
    isLibraries() {
      return this.tabOptions[this.activeTab].key == "libraries";
    },
  },
  watch: {
    property: async function (value) {
      await this.setProperty(value);
      await this.$store.dispatch("filterStore/getSelectedProperty", value);
      await this.$store.dispatch("propertiesStore/setBulkEditProperty", value);
      await this.fetchCurrentPropertyPromotionConfiguration();
      EventBus.$emit("refreshDiscountTable")
    },
    isLibraries(value) {
      this.setDynamicRuntimeProperties(value ? null : this.selectedProperty);
    },
  },
  async destroyed() {
    this.setDynamicRuntimeProperties(null);
    if (!this.$route.fullPath.includes("/promotion-management")) {
      await this.setProperty(null);
      await this.$store.dispatch("filterStore/getSelectedProperty", null);
    }
  },
  methods: {
    ...mapActions({
      fetchPromotions: "revManStore/fetchActivePromotions",
      fetchPromotionPlans: "revManStore/fetchPromotionPlans",
      setProperty: "revManStore/setProperty",
      fetchCurrentPropertyPromotionConfiguration:
        "revManStore/fetchCurrentPropertyPromotionConfiguration",
    }),
    setDynamicRuntimeProperties(data = null) {
      this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: data ? [data] : undefined,
      });
    },
    setPreviousData(data) {
      if (data?.propertyId) {
        // this.isReturnState = data.returnStatus
        this.previousSpaceGroupData = data;
        // if (data.returnStatus == true) this.wasInReturnState = data.returnStatus
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.section-content {
  padding-right: 0px !important;
}
.rm-tab-items {
  background-color: #f9fafb;
}
</style>
